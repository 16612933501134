import React, { useState } from 'react';
import { Container, Row, Col, Accordion, Card, Form, Button } from 'react-bootstrap';
import './ContactUs.css';

const faqs = [
  {
    question: "What is Revenue Operations (RevOps)?",
    answer: "Revenue Operations (RevOps) is a strategic approach that aligns sales, marketing, and customer success teams to create a seamless revenue-generating process. By integrating people, processes, and technology, RevOps ensures that all departments work collaboratively toward common revenue goals, ultimately improving efficiency and driving growth."
  },
  {
    question: "How can RevOps help my business grow?",
    answer: "RevOps creates a unified framework that streamlines operations across departments. By aligning your sales, marketing, and customer success teams, RevOps optimizes the customer journey, improves data-driven decision-making, and identifies bottlenecks in your pipeline, leading to faster growth, better customer retention, and more predictable revenue."
  },
  {
    question: "Why is alignment between sales, marketing, and customer success important?",
    answer: "RevOps can benefit businesses of any size or industry, particularly those experiencing growth or operational inefficiencies. Startups looking to scale, mid-market companies seeking operational efficiency, and large enterprises aiming for better alignment between departments can all see substantial benefits from a RevOps framework."
  },
 
  {
    question: "What types of businesses benefit from RevOps?",
    answer: "When sales, marketing, and customer success operate in silos, miscommunication and inefficiencies arise. RevOps ensures these teams work together seamlessly, using the same data and strategies to create a consistent and efficient customer journey."
  },
  {
    question: " Do I need to overhaul my current tech stack for RevOps?",
    answer: "Not necessarily. RevOps focuses on optimizing existing processes and technologies before recommending new tools. However, if there are gaps in your current tech stack or if it’s hindering your revenue growth, we will suggest integrations or replacements to enhance your operations."
  },
  // Add more FAQ items following the same pattern
];

function ContactUs() {
  const [form, setForm] = useState({ name: '', email: '', message: '' });
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit form logic here
    setSubmitted(true);
  };

  return (
    <Container className="contact-us">
      <h1>Contact Us</h1>

      <Row className="mt-4">
        {/* Contact Form */}
        <Col md={6} className="contact-form">
          <h2>Get in Touch</h2>
          {submitted ? (
            <p>Thank you for reaching out! We'll get back to you shortly.</p>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Your Name"
                  name="name"
                  value={form.name}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mt-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Your Email"
                  name="email"
                  value={form.email}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formMessage" className="mt-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Your Message"
                  name="message"
                  value={form.message}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit" className="mt-4">
                Submit
              </Button>
            </Form>
          )}
        </Col>

        {/* FAQ Section */}
        <Col md={6} className="faq-section">
          <h2>Frequently Asked Questions (FAQs)</h2>
          <Accordion>
            {faqs.map((faq, index) => (
              <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header>{faq.question}</Accordion.Header>
                <Accordion.Body>{faq.answer}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactUs;
