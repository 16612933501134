import React from 'react';
import './VideoSection.css'; // Import CSS for styling

function VideoSection() {
    return (
        <div className="video-section-container">
            <div className="video-container">
                <iframe 
                    className="video" 
                    src="https://www.youtube.com/embed/Cm2T5PPVshE?si=AbPlqEi-05o2p8qn" 
                    title="YouTube video player" 
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" 
                    allowFullScreen>
                </iframe>
            </div>
            <div className="text-container">
                <h2>CEO Moments of Truth</h2>
                <p>Explore the insights and moments shared by our CEO. Get to know the vision and values that drive our business forward.</p>
            </div>
        </div>
    );
}

export default VideoSection;  
