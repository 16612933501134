import React, { useState } from 'react';
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "./Firebase";
import { Link, useNavigate } from "react-router-dom";
import './Auth.css';

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userEmail = userCredential.user.email;
      setMessage(`Welcome, ${userEmail.substring(0, 6)}!`);
      setTimeout(() => navigate("/"), 2000);
    } catch (error) {
      setMessage("You do not have an account with us.");
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      setMessage("Welcome!"); // Customizable message
      setTimeout(() => navigate("/"), 2000);
    } catch (error) {
      setMessage("Error signing in with Google");
    }
  };

  return (
    <div className="auth-container">
      <h2>Sign In</h2>
      {message && <p className="auth-message">{message}</p>}
      <button onClick={handleGoogleSignIn} className="auth-button google-button">Sign in with Google</button>
      <form onSubmit={handleSignIn}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="auth-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="auth-input"
        />
        <button type="submit" className="auth-button">Sign In</button>
      </form>
      <p className="auth-links">
        <Link to="/sign-up">Create an account</Link> | <Link to="/forgot">Forgot Password?</Link>
      </p>
    </div>
  );
}

export default SignIn;
