// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBJzfk5DdRfUX3ppH5WY6Ytwgs4NOwemzY",
    authDomain: "renbsha.firebaseapp.com",
    databaseURL: "https://renbsha-default-rtdb.firebaseio.com",
    projectId: "renbsha",
    storageBucket: "renbsha.firebasestorage.app",
    messagingSenderId: "282341159519",
    appId: "1:282341159519:web:4e78f609fd488462f0a1ea",
    measurementId: "G-H2PRTRX1FL"
  };
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const db = getFirestore(app);

export { auth, googleProvider, db };
