import React, { useState } from 'react';
import { createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "./Firebase";
import { Link, useNavigate } from "react-router-dom";
import { setDoc, doc } from "firebase/firestore";
import { db } from "./Firebase";
import './Auth.css';

function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await setDoc(doc(db, "users", userCredential.user.uid), {
        email,
      });
      setMessage("Account successfully created!");
      setTimeout(() => navigate("/"), 2000);
    } catch (error) {
      setMessage("Error creating account.");
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      const userCredential = await signInWithPopup(auth, googleProvider);
      await setDoc(doc(db, "users", userCredential.user.uid), {
        email: userCredential.user.email,
      });
      setMessage("Account successfully created!");
      setTimeout(() => navigate("/"), 2000);
    } catch (error) {
      setMessage("Error signing up with Google");
    }
  };

  return (
    <div className="auth-container">
      <h2>Create Account</h2>
      {message && <p className="auth-message">{message}</p>}
      <button onClick={handleGoogleSignUp} className="auth-button google-button">Sign up with Google</button>
      <form onSubmit={handleSignUp}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="auth-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="auth-input"
        />
        <button type="submit" className="auth-button">Sign Up</button>
      </form>
      <p className="auth-links">
        <Link to="/sign-in">Already have an account? Sign in</Link>
      </p>
    </div>
  );
}

export default SignUp;
