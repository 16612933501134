import React from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import './Technology.css';

function Technology() {
  return (
    <>
      <div className="hero-image">
        <h1 className="hero-text">Technology, Media, and Telecommunications</h1>
      </div>

      <Container className="mt-5">
        <h2 className="text-center">Exploring Insights</h2>

        {/* Horizontal Cards */}
        <Row className="justify-content-center">
          <Col md={4}>
            <div className="insight-card">
              <img src="https://www.srslegal.pt/xms/img/x800/43e81/cT05MCZmPXdlYnAmZmx0cltdPXVzbQ/L08zbS8tME0zWnJTbS95NHNhU25ETUtqbS9oblNabS9QUzc3Y3AzWnJTbTE1b3E0TDQ1NjRzNHR6dGRrcg.webp" alt="Insight 1" />
              <div className="card-content">
                <h5>Insight Title 1</h5>
                <p>Brief description of insight 1.</p>
                <a href="/insight-1" className="btn btn-link">Learn More</a>
              </div>
            </div>
            <div className="insight-card">
              <img src="https://assets.kpmg.com/is/image/kpmg/chrome-purple-swirl-thumb:cq5dam.web.1200.600" alt="Insight 2" />
              <div className="card-content">
                <h5>Insight Title 2</h5>
                <p>Brief description of insight 2.</p>
                <a href="/insight-2" className="btn btn-link">Learn More</a>
              </div>
            </div>
            <div className="insight-card">
              <img src="https://assets.kpmg.com/is/image/kpmg/chrome-purple-swirl-thumb:cq5dam.web.1200.600" alt="Insight 3" />
              <div className="card-content">
                <h5>Insight Title 3</h5>
                <p>Brief description of insight 3.</p>
                <a href="/insight-3" className="btn btn-link">Learn More</a>
              </div>
            </div>
          </Col>
        </Row>

        {/* How We Help Clients Section */}
        <div className="help-clients mt-5">
          <h2 className="text-center">How We Help Clients</h2>
          <Row className="mt-3">
            <Col md={5} xs={12} className="text-center">
              <p>We provide innovative technology solutions to enhance your business processes.</p>
            </Col>
            <Col md={2} xs={12} className="text-center">
              <div className="vertical-divider" />
            </Col>
            <Col md={5} xs={12} className="text-center">
              <p>Our expertise helps clients navigate the complexities of the technology landscape.</p>
            </Col>
          </Row>
          <div className="horizontal-divider" />
        </div>

        {/* Multi-Item Carousel for Resources */}
        <h2 className="text-center mt-5">Resources</h2>
        <Carousel className="resource-carousel mt-4" interval={null}>
          <Carousel.Item>
            <Row className="justify-content-center">
              <Col md={4}>
                <div className="carousel-card">
                  <img
                    src="https://assets.kpmg.com/is/image/kpmg/chrome-purple-swirl-thumb:cq5dam.web.1200.600"
                    alt="Resource 1"
                    className="d-block w-100"
                  />
                  <h3>Resource Title 1</h3>
                  <p>Brief description for resource 1.</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="carousel-card">
                  <img
                    src="https://assets.kpmg.com/is/image/kpmg/chrome-purple-swirl-thumb:cq5dam.web.1200.600"
                    alt="Resource 2"
                    className="d-block w-100"
                  />
                  <h3>Resource Title 2</h3>
                  <p>Brief description for resource 2.</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="carousel-card">
                  <img
                    src="https://assets.kpmg.com/is/image/kpmg/chrome-purple-swirl-thumb:cq5dam.web.1200.600"
                    alt="Resource 3"
                    className="d-block w-100"
                  />
                  <h3>Resource Title 3</h3>
                  <p>Brief description for resource 3.</p>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row className="justify-content-center">
              <Col md={4}>
                <div className="carousel-card">
                  <img
                    src="https://assets.kpmg.com/is/image/kpmg/chrome-purple-swirl-thumb:cq5dam.web.1200.600"
                    alt="Resource 4"
                    className="d-block w-100"
                  />
                  <h3>Resource Title 4</h3>
                  <p>Brief description for resource 4.</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="carousel-card">
                  <img
                    src="https://assets.kpmg.com/is/image/kpmg/chrome-purple-swirl-thumb:cq5dam.web.1200.600"
                    alt="Resource 5"
                    className="d-block w-100"
                  />
                  <h3>Resource Title 5</h3>
                  <p>Brief description for resource 5.</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="carousel-card">
                  <img
                    src="https://assets.kpmg.com/is/image/kpmg/chrome-purple-swirl-thumb:cq5dam.web.1200.600"
                    alt="Resource 6"
                    className="d-block w-100"
                  />
                  <h3>Resource Title 6</h3>
                  <p>Brief description for resource 6.</p>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </Container>
    </>
  );
}

export default Technology;
