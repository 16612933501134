import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import './Home.css';
import Cards from './Cards'; 
import VideoSection from './VideoSection';

function Home() {
  return (
    <>

    <div className='main-content'>
      <Container fluid className="hero-section">
        <div className="hero-content">
          <h1>Maximize Your Revenue Potential with RenBSha’s Tailored RevOps Solutions</h1>
         
          <Button variant="light" href="about-us">Read More</Button>
        </div>
      </Container>
      
      <div className="home-content"> 
        <h2>Recommended for You: Watch Out for the Latest Trends</h2>
      </div>
      
      <Cards />
      <VideoSection />
      
      {/* New Section with Image on the Right */}
      <Container className="roles-section">
        <Row>
          <Col md={6} className="roles-text"> {/* Text on the left */}
            <h2>Are you seeking interesting roles?</h2>
            <p>
              We have various career opportunities waiting for you. Learn more about our culture and explore exciting roles.
            </p>
            <a href="/careers" className="link-arrow">
              Careers &rarr;
            </a>
            <br />
            <a href="/aboutus" className="link-arrow">
              Explore Our Culture &rarr;
            </a>
          </Col>
          <Col md={6}>
            <img
              src="https://timelycare.com/wp-content/uploads/2023/11/TC_WebsiteHeaderImage-Careers.png" // Replace with your image URL
              alt="Career Opportunities"
              className="roles-image"
            />
          </Col>
        </Row>
      </Container>
      </div>
    </>
  );
}

export default Home;
