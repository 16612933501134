import React from 'react';
import { Container, Button } from 'react-bootstrap';
import './AboutUs.css';

function AboutUs() {
  return (
    <div className="about-us">
      {/* Hero Section */}
      <section className="hero-section1">
        <Container className='main-content1'>
          <h1>From Strategy to Execution – Unlock Predictable Growth with Proven Revenue Operations Strategies That Scale.</h1>
          <Button variant="primary" className="cta-button">Get Your Free Consultation Today</Button>
        </Container>
      </section>

      {/* Section 1: The RenBSha Approach */}
      <section className="about-section">
        <Container>
          <h2>The RenBSha Approach</h2>
          <p>At RenBSha, we believe in simplifying revenue operations to drive real results. Our holistic approach integrates sales, marketing, and customer success strategies, ensuring your revenue streams align and operate seamlessly. Whether you're a growing startup or an enterprise looking to streamline operations, RenBSha provides data-driven solutions that enhance every part of your RevOps journey.</p>
          <h3>What We Offer:</h3>
          <ul>
            <li><strong>Custom RevOps Strategy</strong> – Tailored roadmaps to align your teams with company growth.</li>
            <li><strong>Technology Integration</strong> – Implement the right tools to maximize your tech stack.</li>
            <li><strong>Process Optimization</strong> – Streamline processes across departments for increased efficiency.</li>
            <li><strong>Team Enablement</strong> – Equip your teams with the best practices to drive sustained results.</li>
          </ul>
        </Container>
      </section>

      {/* Section 2: Why RenBSha? */}
      <section className="about-section">
        <Container>
          <h2>Why RenBSha?</h2>
          <p><strong>Results-Oriented. Client-Centric. Proven Impact.</strong> RenBSha isn't just another consultancy – we become your partner. Our hands-on approach ensures that your team is set up for long-term success, and we provide ongoing support to help you achieve consistent growth. Here's why our clients choose us:</p>
          <ul>
            <li><strong>Data-Driven Insights</strong> – We don’t rely on assumptions. Every strategy is backed by detailed analytics and actionable data.</li>
            <li><strong>Expertise Across Industries</strong> – From tech to manufacturing, our consultants have a broad understanding of how to optimize operations in various sectors.</li>
            <li><strong>Tech-Savvy Solutions</strong> – We keep up with the latest advancements in CRM, automation, and analytics platforms to ensure you stay ahead of the curve.</li>
          </ul>
        </Container>
      </section>

      {/* Section 3: Our RevOps Services */}
      <section className="about-section">
        <Container>
          <h2>Our RevOps Services</h2>
          <p>Comprehensive Solutions for Every Growth Stage</p>
          <ul>
            <li><strong>Revenue Strategy Development</strong>: Align your sales, marketing, and customer success with an overarching strategy that fuels sustainable growth.</li>
            <li><strong>CRM & Tech Stack Optimization</strong>: We’ll analyze your current tools and suggest improvements to drive better data insights and improve performance.</li>
            <li><strong>Pipeline Management</strong>: Eliminate bottlenecks and increase efficiency by improving your pipeline visibility from lead generation to deal close.</li>
            <li><strong>Revenue Analytics</strong>: Get deeper insights into your revenue streams and know where your greatest growth opportunities lie.</li>
            <li><strong>Sales Enablement</strong>: Ensure your sales team has the training and resources needed to close more deals faster.</li>
            <li><strong>Customer Journey Mapping</strong>: Identify gaps in your customer experience and improve retention through seamless communication and support.</li>
          </ul>
        </Container>
      </section>

      {/* Section 4: How We Work */}
      <section className="about-section">
        <Container>
          <h2>How We Work</h2>
          <h3>Our 4-Step Process: From Assessment to Success</h3>
          <ul>
            <li><strong>Assess</strong> – We start with a deep dive into your current operations, identifying gaps and areas of opportunity.</li>
            <li><strong>Design</strong> – We create a customized RevOps strategy tailored to your business objectives.</li>
            <li><strong>Implement</strong> – Our team works with yours to implement the strategy, ensuring smooth integration.</li>
            <li><strong>Optimize</strong> – Continuous improvement is key. We provide ongoing analysis and refinement to ensure sustainable results.</li>
          </ul>
        </Container>
      </section>

      {/* Section 5: Book a Free Consultation */}
      <section className="cta-section">
        <Container>
          <h2>Ready to Boost Your Revenue?</h2>
          <p>Get a free, no-obligation consultation with one of our RevOps experts today. Let’s discuss how we can help you drive more revenue with less complexity.</p>
          <Button variant="primary" className="cta-button">Book a Free Consultation</Button>
        </Container>
      </section>
    </div>
  );
}

export default AboutUs;
