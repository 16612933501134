import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import './footer.css'; // Add your custom CSS here

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-left">
        <h2>RenBSha</h2>
        <p>&copy; 2024 RenBSha Consulting Group</p>
      </div>

      <div className="footer-center">
        <a href="#">Contact us</a>
        <a href="#">FAQ</a>
        <a href="#">Privacy Policy</a>
        <a href="#">Terms of Use</a>
      </div>

      <div className="footer-right">
        <p>Follow us:</p>
        <a href="#"><FontAwesomeIcon icon={faLinkedin} /></a>
        <a href="#"><FontAwesomeIcon icon={faFacebook} /></a>
        <a href="#"><FontAwesomeIcon icon={faInstagram} /></a>
        <a href="#"><FontAwesomeIcon icon={faYoutube} /></a>
      </div>
    </footer>
  );
};

export default Footer;
