// src/pages/Header.js
import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './Firebase';
import './Header.css';
import logo from '../assets/logo.png';

function Header() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [user, setUser] = useState(null);

  // Monitor authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe(); // Cleanup listener on unmount
  }, []);

  // Toggle sidebar open/close state
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Handle sign-out
  const handleSignOut = async () => {
    await signOut(auth);
  };

  return (
    <>
      {/* Sidebar */}
      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={toggleSidebar}>
          &times; {/* Close symbol */}
        </button>
        <Nav className="flex-column sidebar-nav">
          <NavDropdown title="Industries" id="sidebar-industries-dropdown">
            <NavDropdown.Item as={Link} to="/industries/finance">Finance</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/technology">Technology</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Capabilities" id="sidebar-capabilities-dropdown">
            <NavDropdown.Item as={Link} to="/capabilities/strategy">Strategy</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/capabilities/digital">Digital</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link as={Link} to="/featured-insights">Featured Insights</Nav.Link>
          <Nav.Link as={Link} to="/careers">Careers</Nav.Link>
          <Nav.Link as={Link} to="/about-us">About Us</Nav.Link>
          <Nav.Link as={Link} to="/contactus">Contact Us</Nav.Link>
        </Nav>
      </div>

      {/* Main Navbar */}
      <Navbar expand="lg" className="custom-navbar" sticky="top">
        <Container className="d-flex align-items-center">
          {/* Hamburger Menu on the left, always visible */}
          <button
            className="navbar-toggler always-visible"
            onClick={toggleSidebar}
            aria-controls="responsive-navbar-nav"
            aria-expanded={sidebarOpen}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Brand Logo */}
          <Navbar.Brand as={Link} to="/" className="mx-3">
            <img src={logo} alt="Company Logo" className="logo" />
          </Navbar.Brand>

          {/* Full Navigation Menu, centered */}
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">
            <Nav className="main-nav">
              <NavDropdown title="Industries" id="navbar-industries-dropdown">
                <NavDropdown.Item as={Link} to="/industries/finance">Finance</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/technology">Technology</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Capabilities" id="navbar-capabilities-dropdown">
                <NavDropdown.Item as={Link} to="/capabilities/strategy">Strategy</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/capabilities/digital">Digital</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to="/featured-insights">Featured Insights</Nav.Link>
              <Nav.Link as={Link} to="/careers">Careers</Nav.Link>
              <Nav.Link as={Link} to="/about-us">About Us</Nav.Link>
              <Nav.Link as={Link} to="/contactus">Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>

          {/* Right-aligned links for Sign In, Subscribe, and User Profile */}
          <Nav className="align-items-center d-none d-lg-flex">
            {user ? (
              <div className="user-profile">
                <span>Welcome, {user.email.substring(0, 6)}!</span>
                <button onClick={handleSignOut} className="sign-out-button">Sign Out</button>
              </div>
            ) : (
              <>
                <Nav.Link as={Link} to="/sign-in" className="sign-in-link">Sign In</Nav.Link>
                <Nav.Link as={Link} to="/sign-up" className="subscribe-link">Subscribe</Nav.Link>
              </>
            )}
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
